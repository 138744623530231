/* Default state - Material UI-like styling */
.e-style {
    border: 1px solid rgba(0, 0, 0, 0.23) !important; /* Default border */
    border-radius: 4px !important; /* Rounded corners */
    padding: 8px 12px !important; /* Material UI-like padding */
    font-family: 'Roboto', sans-serif !important; /* MUI font */
    font-size: 16px !important;
    margin-left: 10px;
    transition: border-color 0.2s ease-in-out, border-width 0.2s ease-in-out !important;
}

/* Ensure floating label wrapper has no extra bottom border */
.e-style .e-float-input.e-control-wrapper {
    border: none !important; /* Remove default border */
}

/* Remove Syncfusion's bottom border */
.e-control-wrapper.e-numeric.e-float-input.e-style .e-float-line::before,
.e-control-wrapper.e-numeric.e-float-input.e-style .e-float-line::after {
    display: none !important; /* Hide default bottom border */
    border-bottom: none !important;
}

/* Focused state - Thicker blue border without bottom line */
.e-style:focus-within {
    border: 2px solid #3f51b5 !important; /* Thicker Material UI blue border */
    box-shadow: none !important;
}

/* Change label color to blue when focused */
.e-style:focus-within .e-float-text {
    color: #3f51b5 !important; /* Material UI primary blue */
}

/* Ensure floating label behaves correctly */
.e-style .e-float-text {
    font-size: 12px !important;
    color: rgba(0, 0, 0, 0.6) !important; /* Default label color */
    transition: all 0.2s ease-in-out !important;
}

/* Move label when input is empty */
.e-style .e-float-input.e-control-wrapper:not(.e-input-focus) .e-float-text {
    transform: translateY(10px) scale(1) !important;
    color: rgba(0, 0, 0, 0.38) !important;
}